<template>
  <div>
    <jaya-connection-layout text="Renseignez vos identifiants de connexion à un compte existant">
      <validation-observer v-slot="{invalid, handleSubmit}">
        <b-form @submit.prevent="handleSubmit(login)">
          <b-row>

            <!-- Email -->
            <b-col cols="12">
              <b-form-group label="Email">
                <validation-provider v-slot="{errors}" rules="required">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MailIcon"/>
                    </b-input-group-prepend>

                    <b-form-input v-model="email"
                        autofocus
                        placeholder="Email"
                        type="email"/>
                  </b-input-group>
                  <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Password -->
            <b-col cols="12">
              <b-form-group label="Mot de passe">
                <validation-provider v-slot="{errors}" rules="required">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"/>
                    </b-input-group-prepend>
                    <b-form-input v-model="password"
                        :type="passwordFieldType"
                        placeholder="Mot de Passe"
                        v-on:keyup.enter="login"/>
                  </b-input-group>
                  <b-form-text class="text-danger">{{ errors[0] }}</b-form-text>
                  <b-form-text v-if="error === 'loginError'" class="text-danger">
                    Identifiant ou mot de passe incorrect.
                  </b-form-text>
                  <b-form-text v-else-if="error === 'serverError'" class="text-danger">
                    Une erreur technique est survenue.
                  </b-form-text>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Remember Me-->
            <b-col class="flex-grow-1" cols="12">
              <b-form-group class="d-flex justify-content-center">
                <b-form-checkbox v-model="checkboxRememberMe" class="pr-1 custom-control-primary">
                  <p class=" text-dark">Rester connecté</p>
                </b-form-checkbox>
                <a class="pb-1">
                  <router-link to="forgot-password">
                    Mot de passe oublié ?
                  </router-link>
                </a>
              </b-form-group>
            </b-col>

            <!-- Buttons-->
            <b-col class="d-flex justify-content-around flex-wrap" cols="12">
              <b-button class="m-25 flex-grow-1" variant="outline-primary"
                  @click="$router.push(`register`)">
                Créer un compte
              </b-button>
              <b-button :disabled="invalid"
                  class="m-25 flex-grow-1"
                  type="submit"
                  variant="primary">
                Me connecter
              </b-button>
            </b-col>

            <!-- Social connect-->
            <b-col v-if="!isIos" class="d-flex justify-content-around flex-wrap" cols="12">
              <jaya-divider class="col-12" color="dark" text="Se connecter avec"/>
              <b-button class="mb-1 google-button col-7" @click="socialUrl('google')">
                <img :src="require('@/assets/images/pages/login/google-white.png')"
                    alt="Logo Google"
                    class="cursor-pointer mr-1"
                    style="height: 1rem; background: #db4a39 !important">
                <span class="align-middle">Google</span>
              </b-button>

              <b-button class="mb-1 facebook-button col-7" style="background: #3b5998 !important; border: 0"
                  @click="socialUrl('facebook')">
                <feather-icon class="mr-1" icon="FacebookIcon"/>
                <span class="align-middle">Facebook</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </jaya-connection-layout>

    <b-modal
        v-model="popUpMoreInfo"
        centered
        hide-footer
        title="Compte inactif"
        @close="deactivatePopUp">
      <p> Il semblerait que votre compte ne soit pas activé. L' e-mail de validation s'est peut-être caché dans vos
        spams. <br>
        Si vous ne le retrouvez pas,
        <span class="cursor-pointer font-medium text-primary" @click="resendEmail">
              cliquez ici
            </span>
        pour un nouvel envoi
        (l'envoi peut prendre jusqu'à quelques minutes).
      </p>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormText,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BModal,
  BRow
} from "bootstrap-vue"
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import JayaConnectionLayout from "@/components/global/JayaConnectionLayout";
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {required} from "vee-validate/dist/rules";

extend('required', {...required, message: 'Ce champ est requis'});

export default {
  name: 'login',
  components: {
    JayaConnectionLayout,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormInput,
    BFormText,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BButton,
    BModal,
    ValidationProvider,
    ValidationObserver,
    extend,
    required,
  },

  mixins: [togglePasswordVisibility],

  data() {
    return {
      email: "",
      password: "",
      checkboxRememberMe: true,
      error: null,
      activePopUp: true,
    };
  },
  beforeCreate() {
    //Delete all user data before connection
    this.$store.commit("DELETE_ALL_USER_DATA", null, {root: true})
  },
  mounted() {
    if (this.$route.hash.split('#')[1] === "disconnect") {
      this.$notify(
          {
            title: "Déconnecté",
            text: "Vous avez été déconnecté",
            time: 4000,
            color: "primary"
          }
      )
      this.$loading(false);
    }
  },
  methods: {
    login() {
      this.$loading(true);
      this.$store.dispatch("account/login", {
        login: this.email,
        password: this.password,
        remember_me: this.checkboxRememberMe
      })
          .then(() => {
            this.$notify({
              time: 4000,
              color: "primary",
              title: "Connecté",
              text: "Bon retour parmi nous !"
            })
            if (process.env.VUE_APP_USE_TOKEN) {
              this.$fireLoginDone();
            }
            this.$router.push({name: "jayaccueil"})
          })
          .catch(error => {
            this.$loading(false);
            this.error = error
          })
    },
    resendEmail() {
      let payload = {email: this.email}
      this.$store.dispatch("account/resend_validation_email", payload)
          .then(() => {
            this.$notify(
                {
                  title: "Renvoi effectué",
                  text: "Un email vous a été renvoyé à l'adresse : " + this.email,
                  time: 4000,
                  color: "primary"
                }
            )
          })
    },
    deactivatePopUp() {
      this.activePopUp = false
    },
    socialUrl(provider) {
      let state = new Uint32Array(1)
      // https://developer.mozilla.org/fr/docs/Web/API/RandomSource/getRandomValues says that these number are strong enough to get crypto
      window.crypto.getRandomValues(state)
      let url = process.env.VUE_APP_BACKEND_URL + "/accounts/social-auth?provider=" + provider
          + "&redirect_uri=" + process.env.VUE_APP_REDIRECT_URI
          + "&state=" + state[0].toString()
      localStorage.setItem("socialState", state[0])
      if (process.env.VUE_APP_USE_TOKEN) {
        // Force using external browser
        window.cordova.InAppBrowser.open(url, '_system')
      } else {
        window.location.href = url
      }
    },
  },
  computed: {
    popUpMoreInfo() {
      return this.$store.state.account.userIsInactive && this.activePopUp
    },
    isIos() {
      return window.cordova && window.cordova.platformId === "ios"
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  }
}
</script>

<style lang="scss">
.google-button {
  background: #db4a39 !important;
  border-width: 0;
}

.google-button:hover {
  box-shadow: 0 8px 25px -8px #db4a39 !important;
}

.facebook-button {
  background: #3b5998 !important;
  border-width: 0;
}

.facebook-button:hover {
  box-shadow: 0 8px 25px -8px #3b5998 !important;
}
</style>
